import { usePickUpNumber } from "@/hooks/usePickUpNumber"
import { useListig } from "@/hooks/useListig"
import { useStoreInformation } from "@/hooks/useStoreInformation"
import useWindowSize from "@/hooks/useWindowSize"
import Barcode from "react-barcode"
import { useTranslation } from "react-i18next"
import ExternalWarehouseCard from "./ExternalWarehouseCard"
import OrderProducts from "./OrderProducts"
import OrderCompleted from "./OrderCompleted"
import PickupNumber from "./PickupNumber"
import OrderStatus from "./OrderStatus"
import { useEffect, useState } from "react"
import { FullServeSurvey } from "./FullServeSurvey"
import useVisit from "@/hooks/useVisit"
import { sendFullServeSurveyEvent } from "@/analytics/events/sendFullServeSurveyEvent"
import { AnimatePresence, motion } from "framer-motion"
import FullServeStatusText from "./FullServeStatusText"
import { getFullServeStatus } from "@/utils/getFullServeStatus"
import SSRIcon from "@ingka/ssr-icon"
import arrowLeft from "@ingka/ssr-icon/paths/arrow-left"
import pencil from "@ingka/ssr-icon/paths/pencil"
import { TotalPriceReceipt } from "../list/TotalPriceReceipt"
import { calculatePriceTotals } from "@/utils/calculatePriceTotals"
import classNames from "classnames"
import { InstoreOrder, UpdateDeliveryArrangement } from "@/types/order"
import { EditOrderModal } from "./EditOrderModal"
import { isIsomFullServe } from "@/utils/fullServeRestrictions"
import { useLocale } from "@/hooks/useLocale"
import { sendOrderOptionsClickEvent } from "@/analytics/events/sendOrderOptionsClickEvent"
import { OrderInformation } from "./OrderInformation"
import { useWaitingTime } from "@/hooks/useWaitingTime"
import {
  FullServeProduct,
  OnlineProduct,
} from "@/types/product/categorizedProduct"
import { useEditOrders } from "@/hooks/useEditOrders"
import { useTimeSlotManagement } from "@/hooks/useTimeSlotManagement"
import { TimeSlotOrderView } from "./TimeSlotOrderView"
import { useCcOrder } from "@/hooks/useCcOrder"

const BARCODE_LENGTH = 16

interface OrderViewProps {
  order: InstoreOrder
  parentProducts: (FullServeProduct | OnlineProduct)[]
  onClose: () => void
  onCancelOrder: () => void
}

export const OrderView: React.FC<OrderViewProps> = ({
  order,
  parentProducts,
  onClose,
  onCancelOrder,
}) => {
  const { t } = useTranslation()
  const { session } = useListig()
  const { pickupNumber } = usePickUpNumber(order.orderNo)
  const { width: windowWidth } = useWindowSize()
  const scaleFactor = (Math.min(windowWidth, 415) * 0.8) / 290
  const { storeInfo } = useStoreInformation(session.businessUnitCode ?? null)
  const { visited, setVisitedPage } = useVisit()
  const [showEditOrderModal, setShowEditOrderModal] = useState(false)
  const fullServeStatus = getFullServeStatus(order.isPaid, order.pickingStatus)
  const products = order.items ?? []
  const totalPrice = calculatePriceTotals(products)
  const { market } = useLocale()
  const isWaitingTimeEnabled = useWaitingTime()
  const isEditOrdersEnabled = useEditOrders()
  const { editTimeSlotOnOrder } = useCcOrder()
  const isTimeSlotManagementEnabled = useTimeSlotManagement()
  const isIsom = isIsomFullServe(
    market,
    session.businessUnitCode ?? "",
    session.source?.type === "kiosk" ? session.source.ukid : "",
  )

  /** Fetch external warehouse */
  const externalWarehouse =
    storeInfo &&
    "externalPickupPoint" in storeInfo &&
    storeInfo.externalPickupPoint

  const [showPartialOrderView, setShowPartialOrderView] = useState(false)

  /** If order has pickingStatus it means we can show detailed information about the order status.
   *  If the pickingStatus is not COMPLETED that means the order hasn't been picked up and we can show
   *  detailed information about the current order progress.
   */
  const isNotPickedUp = order.pickingStatus !== "COMPLETED"

  /** If order has been paid for, order amounts to pay should be 0 */
  const isPaidForAndIsntPickedUp = order.isPaid && isNotPickedUp

  useEffect(() => {
    if (order.isPartial) {
      setTimeout(() => {
        setShowPartialOrderView(true)
      }, 60000)
    }

    if (showPartialOrderView && !order.isPartial) {
      setShowPartialOrderView(false)
    }
  }, [order, showPartialOrderView, setShowPartialOrderView])

  return (
    <>
      <div
        data-cy="order-view"
        className="flex flex-col bg-white min-h-[inherit]"
      >
        <div
          className={classNames(
            "h-20 flex justify-center pt-[45px] text-white font-bold text-sm sticky top-0 z-10",
            {
              "bg-blue-brand": fullServeStatus !== "READY_FOR_PICKUP",
              "bg-semantic-positive": fullServeStatus === "READY_FOR_PICKUP",
            },
          )}
        >
          <SSRIcon
            data-cy="order-view-back-button"
            className="absolute left-5"
            paths={arrowLeft}
            onClick={onClose}
          />
          {`${t("mobile.order")} ${pickupNumber}`}
          {!order.isPaid && isIsom && isEditOrdersEnabled && (
            <SSRIcon
              data-cy="open-edit-order"
              className="absolute right-5"
              paths={pencil}
              onClick={() => {
                sendOrderOptionsClickEvent(order.orderNo)
                setShowEditOrderModal(true)
              }}
            />
          )}
        </div>

        <div className="divide-y divide-gray-200">
          <div className="flex flex-col p-6 space-y-10">
            {isNotPickedUp && (
              <OrderStatus
                isWaitingForPayment={!order.isPaid}
                pickingStatus={order.pickingStatus}
                showStatus={!showPartialOrderView}
              />
            )}

            {(!order.isPaid || showPartialOrderView) && (
              <p className="text-base font-bold text-black">
                {t("mobile.pay-at-payment-point")}
              </p>
            )}

            {isPaidForAndIsntPickedUp && (
              <div className="flex flex-col items-center space-y-2">
                <PickupNumber
                  pickupNumber={pickupNumber}
                  pickingStatus={order.pickingStatus}
                />
                <div className="flex flex-col items-center">
                  <FullServeStatusText status={fullServeStatus} />
                </div>
              </div>
            )}

            <div
              className={classNames("flex flex-col space-y-10", {
                "flex-col-reverse space-y-reverse": !order.isPaid,
              })}
            >
              <OrderInformation
                status={fullServeStatus}
                orderPaidDate={order.orderPaidDate}
                estimatedReadyTime={order.estimatedReadyTime}
                isWaitingTimeEnabled={isWaitingTimeEnabled}
                location={order.isPaid ? "center" : "left"}
              />

              {isNotPickedUp && fullServeStatus !== "PREPARING_ORDER" && (
                <div className="flex flex-col">
                  <div
                    data-cy="order-view-barcode"
                    className="flex flex-col self-center"
                    style={{ transform: `scaleX(${scaleFactor})` }}
                  >
                    <Barcode
                      value={order.orderNo.padStart(BARCODE_LENGTH, "0")}
                      width={2}
                      height={48}
                      // "ITF" is currently not supported by the wrapping library
                      // eslint-disable-next-line @typescript-eslint/no-explicit-any
                      format={"ITF" as any}
                      displayValue={false}
                      margin={0}
                      data-cy="barcode"
                    />
                  </div>

                  <p className="text-sm text-gray-500 mt-3">
                    {t("Interstitial-BarcodeModal-code-label")}
                    <span className="font-mono">{order.orderNo}</span>
                  </p>
                </div>
              )}
            </div>

            {/* TODO: It should be possible for customer to change the time all through the journey, except for in the final minutes when co-worker has marked that they are in the final picking stage. */}
            {fullServeStatus !== "READY_FOR_PICKUP" &&
              fullServeStatus !== "COLLECTED" &&
              isTimeSlotManagementEnabled &&
              order?.timeSlot && (
                <TimeSlotOrderView
                  status={fullServeStatus}
                  timeSlot={order.timeSlot}
                  items={order.items}
                  onContinue={(detailedTimeSlot: UpdateDeliveryArrangement) => {
                    editTimeSlotOnOrder({
                      order,
                      detailedTimeSlot,
                    })
                  }}
                />
              )}

            {showPartialOrderView && (
              <>
                <div>
                  <p className="text-base text-gray-600">
                    {t("mobile.collect-pickup-point")}
                  </p>
                  <p className="text-sm">
                    {t("mobile.when-ready-show-barcode")}
                  </p>
                </div>
                <PickupNumber
                  pickupNumber={pickupNumber}
                  pickingStatus={order.pickingStatus}
                  location="left"
                />
              </>
            )}

            {externalWarehouse &&
              storeInfo &&
              storeInfo.lat &&
              storeInfo.lng &&
              (isNotPickedUp || showPartialOrderView) && (
                <ExternalWarehouseCard
                  externalWarehouse={externalWarehouse}
                  origin={{
                    lat: storeInfo.lat,
                    long: storeInfo.lng,
                  }}
                />
              )}

            {session.businessUnitCode &&
              order.pickingStatus === "COMPLETED" && (
                <div>
                  <AnimatePresence>
                    {!visited.fullServeSurvey && (
                      <motion.div
                        style={{ overflow: "hidden" }}
                        initial={{ height: "auto" }}
                        animate={{ height: "auto" }}
                        transition={{ duration: 0.5, delay: 1 }}
                        exit={{ height: 0 }}
                      >
                        <FullServeSurvey
                          onComplete={(rating) => {
                            setVisitedPage("fullServeSurvey")
                            sendFullServeSurveyEvent(rating)
                          }}
                        />
                      </motion.div>
                    )}
                  </AnimatePresence>

                  <OrderCompleted
                    orderNumber={order.orderNo}
                    orderCreationDate={order.orderCreationDate}
                  />
                </div>
              )}
          </div>

          <OrderProducts products={parentProducts} />

          <div className="py-16 px-7">
            <TotalPriceReceipt
              currency={totalPrice.currency}
              familyPrice={totalPrice.family}
              totalPrice={totalPrice.regular}
              secondCurrency={totalPrice.secondCurrency}
              secondTotalPrice={totalPrice.secondaryPrice}
              secondFamily={totalPrice.secondFamily}
            />
          </div>
        </div>
      </div>
      <EditOrderModal
        orderNo={order.orderNo}
        visible={showEditOrderModal}
        onClose={() => setShowEditOrderModal(false)}
        onCancelOrder={onCancelOrder}
      />
    </>
  )
}
