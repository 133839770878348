import { TimeSlot, UpdateDeliveryArrangement } from "@/types/order"
import { StoreAddress } from "@/types/responses/admin"
import { fetchWithErrorHandling } from "@/utils/fetch"
import { getTaxAddress } from "@/utils/taxAddress"
import { parseTimeSlot } from "@/utils/timeSlots"

interface Props {
  market: string
  language: string
  storeNo: string
  orderNo: string
  orderNoSource: string
  kongToken: string
  detailedTimeSlot: UpdateDeliveryArrangement
  storeAddress?: StoreAddress
}

export const updateTimeSlot = ({
  market,
  language,
  storeNo,
  orderNo,
  orderNoSource,
  kongToken,
  detailedTimeSlot,
  storeAddress,
}: Props): Promise<TimeSlot[]> => {
  const taxAddress = getTaxAddress(market, undefined, storeAddress)

  detailedTimeSlot.timeWindow = parseTimeSlot(
    detailedTimeSlot,
    storeAddress?.timezone,
  )

  return fetchWithErrorHandling(
    "BUY_UPDATE_TIME_SLOT",
    `${process.env.BUY_API_URL}/time-slots/${market}/${language}/${storeNo}/${orderNo}/${orderNoSource}`,
    {
      headers: {
        authorization: `Bearer ${kongToken}`,
        "Content-Type": "application/json",
      },
      method: "PUT",
      body: JSON.stringify({
        updateDeliveryArrangement: detailedTimeSlot,
        taxAddress,
      }),
    },
  )
}
