/* eslint-disable filename-rules/match */
import { OAuthResponse } from "@/types/auth"
import { fetchWithErrorHandling } from "@/utils/fetch"
import {
  getCachedOAuthToken,
  setCachedOAuthToken,
} from "@/utils/oAuthLocalStorage"

export type OAuthToken = {
  token: string
  /** Timestamp in ms */
  expiry: number
}

export const getUpptackaJWT = async () =>
  (
    await fetchWithErrorHandling<OAuthResponse>(
      "AUTH",
      process.env.AUTH_API_URL + "/v2/token/upptacka/mobile",
    )
  ).access_token

export async function getOAuthToken(): Promise<OAuthToken> {
  if (typeof process.env.AUTH_API_URL !== "string")
    throw new Error("AUTH_API_URL not set")

  const cachedToken = getCachedOAuthToken()
  if (cachedToken && cachedToken.expiry > Date.now()) return cachedToken

  const authToken = await getUpptackaJWT()
  const responseBody: OAuthResponse = await fetchWithErrorHandling(
    "OAUTH_TOKEN",
    process.env.AUTH_API_URL + "/v2/token/m2m/mobile",
    {
      headers: {
        Authorization: "Bearer " + authToken,
      },
    },
  )

  // - 300 seconds to give us a small breathing room in case application runs slow
  const secondsLeft = responseBody.expires_in - 300

  const oAuthToken: OAuthToken = {
    token: responseBody.access_token,
    expiry: Date.now() + secondsLeft * 1000,
  }

  setCachedOAuthToken(oAuthToken, secondsLeft)
  return oAuthToken
}
