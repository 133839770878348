import Keps from "@/analytics/keps"
import { useCookieConsent } from "@/hooks/useCookieConsent"
import { useListig } from "@/hooks/useListig"
import { useLocale } from "@/hooks/useLocale"
import { useStoreInformation } from "@/hooks/useStoreInformation"
import { ReactElement, useEffect } from "react"

/**
 * Initialize analytics session.
 */
export const AnalyticsWrapper: React.FC<{ children: ReactElement }> = ({
  children,
}) => {
  const { session } = useListig()
  const { source, businessUnitCode, listId } = session
  const { market, language } = useLocale()
  const { isPerformanceEnabled } = useCookieConsent()
  const { storeInfo } = useStoreInformation(businessUnitCode ?? "")

  useEffect(() => {
    if (
      market &&
      language &&
      source &&
      businessUnitCode &&
      listId &&
      storeInfo &&
      (isPerformanceEnabled || market.toUpperCase() === "CN")
    ) {
      Keps.initializeAnalytics({
        market,
        language,
        storeNo: businessUnitCode,
        listId,
        timezone: storeInfo.address?.timezone,
      })
    } else if (!isPerformanceEnabled) {
      Keps.disableAnalytics()
    }
  }, [
    market,
    source,
    language,
    businessUnitCode,
    listId,
    isPerformanceEnabled,
    storeInfo,
  ])

  return children
}
