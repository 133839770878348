import { useLocale } from "@/hooks/useLocale"
import { useNavigate } from "react-router"
import { useSearchParams } from "react-router-dom"

export function useSwitchLanguage() {
  const { market } = useLocale()
  const navigate = useNavigate()
  const [params] = useSearchParams()

  const currentPath = location.pathname
  const currentLang = currentPath.split("/")[2]

  return {
    switchLanguage: (newLang: string) => {
      if (!market || !currentLang || currentLang === newLang) return

      const newPath = currentPath.replace(
        `${market.toLowerCase()}/${currentLang}`,
        `${market.toLowerCase()}/${newLang}`,
      )
      navigate(newPath + (params ? "?" + params.toString() : ""))
      window.location.reload()
    },
  }
}
