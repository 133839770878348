import {
  DeliveryArrangement,
  DetailedTimeSlot,
  UpdateDeliveryArrangement,
} from "@/types/order"
import { getLocalDateTimeFromUtcDateTime } from "./formatDateTime"

export const parseTimeSlot = (
  updateDeliveryArrangement: UpdateDeliveryArrangement,
  timeZone?: string,
): DetailedTimeSlot => {
  return {
    ...updateDeliveryArrangement.timeWindow,
    fromDateTime: getLocalDateTimeFromUtcDateTime(
      updateDeliveryArrangement.timeWindow.fromDateTime,
      timeZone,
    ),
    toDateTime: getLocalDateTimeFromUtcDateTime(
      updateDeliveryArrangement.timeWindow.toDateTime,
      timeZone,
    ),
    paymentCutOffDateTime: getLocalDateTimeFromUtcDateTime(
      updateDeliveryArrangement.timeWindow.paymentCutOffDateTime,
      timeZone,
    ),
    hardCutOffDateTime:
      updateDeliveryArrangement.timeWindow.hardCutOffDateTime &&
      getLocalDateTimeFromUtcDateTime(
        updateDeliveryArrangement.timeWindow.hardCutOffDateTime,
        timeZone,
      ),
  }
}

export const buildUpdateTimeSlotPayload = (
  deliveryArrangement: DeliveryArrangement,
  selectedTimeSlot: DetailedTimeSlot,
): UpdateDeliveryArrangement => {
  // eslint-disable-next-line @typescript-eslint/no-unused-vars
  const { timeWindows, ...deliveryArrangementWithoutTimeWindows } =
    deliveryArrangement
  const updateDeliveryArrangement: UpdateDeliveryArrangement = {
    ...deliveryArrangementWithoutTimeWindows,
    timeWindow: selectedTimeSlot,
  }
  return updateDeliveryArrangement
}
