import { DeliveryArrangement } from "@/types/order"
import { fetchWithErrorHandling } from "@/utils/fetch"

export const getDetailedTimeSlots = (
  market: string,
  language: string,
  token: string,
  buCode: string,
  items: { itemId: string; quantity: number }[],
): Promise<DeliveryArrangement> =>
  fetchWithErrorHandling(
    "BUY_GET_TIME_SLOTS",
    `${process.env.BUY_API_URL}/time-slots/detailed/${market}/${language}/${buCode}`,
    {
      headers: {
        authorization: `Bearer ${token}`,
        "Content-Type": "application/json",
      },
      method: "POST",
      body: JSON.stringify({ items }),
    },
  )
