import {
  ExternalPickupPoint,
  WeeklyOpeningHours,
} from "@/types/responses/explore"
import Button from "@ingka/button"
import { useTranslation } from "react-i18next"
import LocationPin from "@ingka/ssr-icon/paths/location-pin"
import { distance } from "@/utils/calcDistance"
import { measureMap } from "@/config/locales"
import { useLocale } from "@/hooks/useLocale"
import { useStoreInformation } from "@/hooks/useStoreInformation"
import { sendOpenMapEvent } from "@/analytics/events/sendOpenMapEvent"
import { useListig } from "@/hooks/useListig"

interface Props {
  externalWarehouse: ExternalPickupPoint
  origin: { lat: string; long: string }
}
export default function ExternalWarehouseCard({
  externalWarehouse,
  origin,
}: Props) {
  const configuredMapProviders = getConfiguredMapProviders()
  const { t } = useTranslation()
  const today = new Date().getDay()
  const { market } = useLocale()
  const { session } = useListig()
  const { storeInfo } = useStoreInformation(session.businessUnitCode ?? null)

  function mapsSelector() {
    if (
      configuredMapProviders.has("apple") &&
      (navigator.userAgent.indexOf("iPhone") != -1 ||
        navigator.userAgent.indexOf("iPod") != -1 ||
        navigator.userAgent.indexOf("iPad") != -1)
    ) {
      window.open(
        `http://maps.apple.com/?saddr=${origin?.lat},${origin?.long}&daddr=${externalWarehouse?.lat},${externalWarehouse?.lng}&dirflg=d`,
      )
    } else if (configuredMapProviders.has("google")) {
      window.open(
        `https://www.google.com/maps/dir/?api=1&origin=${origin?.lat},${origin?.long}&destination=${externalWarehouse?.lat},${externalWarehouse?.lng}&travelmode=driving`,
      )
    }
  }

  const weekday = WEEK_DAYS_SHORT[today]
  const hoursToday = weekday && externalWarehouse.hours?.[weekday]
  const measureUnit = measureMap[market.toLowerCase()]?.unit ?? "metric"

  const calcDistance = distance(
    parseFloat(origin.lat),
    parseFloat(origin.long),
    parseFloat(externalWarehouse.lat ?? ""),
    parseFloat(externalWarehouse.lng ?? ""),
    measureUnit === "metric" ? "kilometers" : "imperial",
  )

  const externalWarehouseDistance = parseFloat(calcDistance.toFixed(2))

  return (
    <div className="flex w-full flex-col justify-between bg-gray-100 p-6 mb-8">
      <div className="mb-0 text-sm leading-relaxed text-gray-700">
        <h5 className="mb-1 font-bold text-base">
          {t("ExternalWarehouseCard-pickup-location")}
        </h5>
        {storeInfo && (
          <p className="text-xs m-0 pb-1">
            {`${externalWarehouseDistance} ${
              measureUnit === "metric" ? "km" : "miles"
            } ${t("mobile.distance-from")} ${storeInfo.name}`}
          </p>
        )}
        {hoursToday && (
          <p className="mb-4 text-xs">
            {t("ExternalWarehouseCard-opening-hours")}
            {": "}
            {hoursToday.open}
            {"-"}
            {hoursToday.close}
          </p>
        )}
        <p className="mb-1 text-sm">{externalWarehouse.name}</p>
        <p className="mb-1 text-sm">{externalWarehouse.street}</p>
        <p className="text-sm">
          {externalWarehouse.zipCode} {externalWarehouse.city}
        </p>
      </div>
      <div className="flex flex-row">
        {configuredMapProviders.size > 0 && (
          <Button
            ssrIcon={LocationPin}
            className="mt-4 bg-white w-auto"
            onClick={() => {
              sendOpenMapEvent()
              mapsSelector()
            }}
            text={t("common.open-map")}
            type="secondary"
            size="small"
          />
        )}
      </div>
    </div>
  )
}

const WEEK_DAYS_SHORT: (keyof WeeklyOpeningHours)[] = [
  "SUN",
  "MON",
  "TUE",
  "WED",
  "THU",
  "FRI",
  "SAT",
]
const MapProviders = ["google", "apple"]

/**
 * Get enabled map providers from .env
 * @returns Set with all enabled providers
 */
const getConfiguredMapProviders = () => {
  const enabledMapProviders = process.env.MAP_PROVIDERS?.split(/\s*,\s*/).map(
    (map) => map.toLowerCase(),
  )

  enabledMapProviders?.forEach((map) => {
    // eslint-disable-next-line no-console
    if (!MapProviders.includes(map)) console.warn("Invalid map provider: ", map)
  })

  return new Set<string>(enabledMapProviders)
}
