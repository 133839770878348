export const getTimeFromDateTime = (dateTimeString: string): string => {
  const dateTime = new Date(dateTimeString)
  const hours = dateTime?.getHours()
  const minutes = dateTime?.getMinutes()

  const formattedMinutes =
    minutes !== undefined
      ? minutes < 10
        ? `0${minutes}`
        : `${minutes}`
      : undefined

  return `${hours}:${formattedMinutes}`
}

export const getLocalDateTimeFromUtcDateTime = (
  dateTimeString: string,
  timeZone?: string,
): string => {
  const localeThatMatchesIsoFormat = "sv-SE"
  return new Date(dateTimeString)
    .toLocaleString(localeThatMatchesIsoFormat, {
      timeZone: timeZone,
    })
    .replace(" ", "T")
}
