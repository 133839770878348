import { Query } from "@tanstack/react-query"
import { useQueryWithErrorHandling } from "./useReactQuery"
import { getOAuthToken, getUpptackaJWT, OAuthToken } from "@/api/getOAuthToken"

/**
 * Sets up OAuth token which will be used for all our API calls
 */
export function useAuth() {
  const { data: oAuthToken, error: oAuthError } = useQueryWithErrorHandling(
    ["token", "oauth"],
    () => getOAuthToken(),
    {
      refetchOnWindowFocus: true,
      refetchInterval: tokenExpiryTime,
      staleTime: tokenExpiryTime,
      retry: 3,
    },
  )

  return {
    oAuthToken:
      oAuthToken && oAuthToken.expiry > Date.now()
        ? oAuthToken.token
        : undefined,
    oAuthError,
  }
}

export const useUpptackaToken = () =>
  useQueryWithErrorHandling(["token", "upptacka"], () => getUpptackaJWT()).data

const tokenExpiryTime: (
  query: Query<OAuthToken, Error, OAuthToken, string[]>,
) => number = (query) => {
  const token = query.state.data
  return token ? token.expiry - Date.now() - 10000 : Infinity
}
