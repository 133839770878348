import Button from "@ingka/button"
import { useEffect, useState } from "react"
import { TimeSlot, UpdateDeliveryArrangement } from "@/types/order"
import { getTimeFromDateTime } from "@/utils/formatDateTime"
import { useTranslation } from "react-i18next"
import { FullServeStatus } from "./FullServeStatusText"
import { TimeSlotsModal } from "./TimeSlotsModal"
import { useSession } from "@/hooks/useListig"
import { sendChangeTimeSlotEvent } from "@/analytics/events/sendChangeTimeSlotEvent"
import { canChangeTimeSlot } from "@/utils/canChangeTimeSlot"
import {
  FullServeProduct,
  OnlineProduct,
} from "@/types/product/categorizedProduct"
import { useTimeSlotManagementUpdate } from "@/hooks/useTimeSlotManagement"
import { useGetDetailedTimeSlots } from "@/hooks/useGetDetailedTimeSlots"
import { buildUpdateTimeSlotPayload } from "@/utils/timeSlots"

interface TimeSlotOrderViewProps {
  status?: FullServeStatus
  timeSlot: TimeSlot
  items?: (FullServeProduct | OnlineProduct)[]
  onContinue: (updateDeliveryArrangement: UpdateDeliveryArrangement) => void
}

export const TimeSlotOrderView: React.FC<TimeSlotOrderViewProps> = ({
  status,
  timeSlot,
  items,
  onContinue,
}) => {
  const { t } = useTranslation()
  const { session } = useSession()
  const { data: detailedTimeSlots } = useGetDetailedTimeSlots(
    session.businessUnitCode,
    items,
  )
  const timeSlots = detailedTimeSlots?.timeWindows
  const isTimeSlotManagementUpdateEnabled = useTimeSlotManagementUpdate()

  const [showTimeSlotModal, setShowTimeSlotModal] = useState<boolean>(false)
  const [showChangeTimeSlot, setShowChangeTimeSlot] = useState<boolean>(false)

  useEffect(() => {
    setShowChangeTimeSlot(
      canChangeTimeSlot(isTimeSlotManagementUpdateEnabled, timeSlots, timeSlot),
    )
  }, [timeSlots, timeSlot, isTimeSlotManagementUpdateEnabled])

  return (
    <div>
      <div className="flex flex-row justify-between">
        <div>
          <div className="text-sm">{t("mobile.collection-time")}:</div>
          <div className="text-xl pb-2 pt-1" data-cy="collection-time">
            {getTimeFromDateTime(timeSlot.fromDateTime) +
              " - " +
              getTimeFromDateTime(timeSlot.toDateTime)}
          </div>
        </div>

        {timeSlots && showChangeTimeSlot && (
          <div>
            <Button
              type="tertiary"
              className="bg-neutral-100"
              size="small"
              onClick={() => {
                sendChangeTimeSlotEvent("order_details")
                setShowTimeSlotModal(true)
              }}
            >
              {t("common.change")}
            </Button>

            <TimeSlotsModal
              timeSlots={timeSlots}
              chosenTimeSlotId={
                timeSlots.find((ts) => ts.id === timeSlot.id)?.id ??
                timeSlots[0]?.id
              }
              show={showTimeSlotModal}
              onClose={() => setShowTimeSlotModal(false)}
              onContinue={(timeSlotId: string) => {
                const selectedTimeSlot = timeSlots.find(
                  (ts) => ts.id === timeSlotId,
                )
                if (selectedTimeSlot) {
                  onContinue(
                    buildUpdateTimeSlotPayload(
                      detailedTimeSlots,
                      selectedTimeSlot,
                    ),
                  )
                }
                setShowTimeSlotModal(false)
              }}
            />
          </div>
        )}
      </div>

      {status === "WAITING_FOR_PAYMENT" && (
        <div className="text-sm">
          {t("mobile.pay-later-new-time", {
            time: getTimeFromDateTime(timeSlot.paymentCutOffDateTime),
          })}
        </div>
      )}
    </div>
  )
}
