import { EDIT_ORDERS_ENABLED, EDIT_ORDERS_LOCATIONS } from "@/config/constants"
import { useLocale } from "@/hooks/useLocale"
import { useListig } from "@/hooks/useListig"
import { isEnabled } from "@/utils/isEnabled"
import { isIsomFullServe } from "@/utils/fullServeRestrictions"

/**
 * @returns true if edit orders has been enabled for market/store/kiosk
 */
export function useEditOrders() {
  const { market } = useLocale()
  const { session } = useListig()
  const { businessUnitCode, source } = session
  const ukid = source?.type === "kiosk" ? source.ukid : undefined
  const isIsom = isIsomFullServe(market, businessUnitCode ?? "", ukid ?? "")

  return (
    isIsom &&
    isEnabled({
      market,
      businessUnitCode,
      ukid,
      ENABLED: EDIT_ORDERS_ENABLED,
      ENABLED_LOCATIONS: EDIT_ORDERS_LOCATIONS,
    })
  )
}
